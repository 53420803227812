.stepwizard-step p {
    margin-top: 10px;
    text-align: center;
}

.stepwizard-row {
    display: table-row;
}

.stepwizard {
    top: 10px;
    display: table;
    width: 100%;
    position: relative;
    margin-bottom: 4rem !important;
}

.stepwizard-step button[disabled] {
    opacity: 1 !important;
    filter: alpha(opacity=100) !important;
}

.stepwizard-step {
    display: table-cell;
    text-align: center;
    position: relative;
    width: 150px;
}

/* .stepwizard-step:before {
    display: table-row;
    top: 25px;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 100%;
    height: 3px;
    background-color: #2F5376; 
} */
.stepwizard-row li:after {
    width: 100%;
    height: 2px;
    content: '';
    position: absolute;
    background-color: #d1d1d1;
    top: 25px;
    left: 50%;
    z-index: -1;
}

.stepwizard-row li.active:after {
    background-color: #5FB773;
}

.stepwizard-row li:last-child:after {
    content: none;
}

/* .stepwizard-row:before {
    top: 25px;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 100%;
    height: 3px;
    background-color: #2F5376;    
} */

.btn-circle {
    width: 50px;
    height: 50px;
    text-align: center;
    padding: 6px 0;
    font-size: 20px;
    line-height: 1.428571429;
    border-radius: 30px;
}

.btn-circle:focus {
    border-color: #fff;
    box-shadow: 0 0 0 0.1rem #5FB773;
}

.btn-circle:hover {
    color: #FFFFFF;
    border-color: #fff;
    background-color: #5FB773;
    box-shadow: 0 0 0 0.1rem #5FB773;
}

.btn-step {
    color: #FFFFFF;
}

.btn-step-complete {
    background-color: #5FB773;
    border-color: #5FB773;
}

.btn-step-active {
    background-color: #5FB773;
    color: #FFFFFF;
    border-color: #fff;
    box-shadow: 0 0 0 0.1rem #5FB773;
}