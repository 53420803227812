.login {
  position: absolute;
  background-color: whitesmoke;
  min-height: 100%;
  min-width: 100%;
}

.card-signin {
  border: 0;
  border-radius: 5rem;
  box-shadow: 0 2.5rem 1rem 0 rgba(80, 54, 0, 0.1);
}

.card-signin .card-title {
  margin-bottom: 2rem;
  font-weight: bold;
  font-size: 1rem;
}

.or-seperator {
  margin: 20px 0 10px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  border-top: 1px solid #ccc;
}

.or-seperator i {
  padding: 0 10px;
  background: #FFFFFF;
  position: relative;
  top: -11px;
  z-index: 1;
}

.card-signin .card-body {
  padding: 2rem;
}

.btn-google {
  color: white;
  background-color: #ea4335;
  border-radius: 50px;
}

.btn-circle-login {
  background-color: #1ED760 !important;
  color: #FFFFFF !important;
  border-radius: 50px;
}

.btn-facebook {
  color: white;
  background-color: #3b5998;
  border-radius: 50px;
}
.text-center-logo {
  color: white;
}

.font-small-light {
  font-size: 12px;
}

/* Fallback for Edge
-------------------------------------------------- */

@supports (-ms-ime-align: auto) {
  .form-label-group>label {
    display: none;
  }

  .form-label-group input::-ms-input-placeholder {
    color: #777;
  }
}

/* Fallback for IE
-------------------------------------------------- */

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .form-label-group>label {
    display: none;
  }

  .form-label-group input:-ms-input-placeholder {
    color: #777;
  }
}

#container-login  {
  overflow: auto !important;
}


.menu,
.menu>h1 {
  transition: 1s all
}

.menu {
  text-align: center;
  overflow: hidden;
  padding-bottom: 5%
}

.menu>h1 {
  cursor: default;
  font-size: 10vw;
  margin: 2% 0 0
}

.menu a {
  transition: .25s all;
  text-decoration: none;
  color: #fff;
  margin: 10px 0;
  font-size: 3vw
}

.menu div {
  position: relative
}

@keyframes transitionLeft {
  100% {
    left: 0
  }
}

@keyframes transitionRight {
  100% {
    right: 0
  }
}

.menu div:nth-child(odd) {
  right: 60%;
  animation: 1s transitionRight forwards;
}

.menu div:nth-child(even) {
  left: 60%;
  animation: 1s transitionLeft forwards;
}

.menu div:nth-child(3) {
  animation-delay: 0.5s;
}

.menu div:nth-child(4) {
  animation-delay: 1.0s;
}

.menu div:nth-child(5) {
  animation-delay: 1.5s;
}

.menu div:nth-child(6) {
  animation-delay: 2.0s;
}

.menu div:nth-child(7) {
  animation-delay: 2.5s;
}