.text-dashboard {
  font-size: 2rem;
}

.select-prepend {
  margin-right: -3px;
  width: 110%
}

.btn-form-inline {
  margin-top: 1.4em;
}

.btn-form-inline-fix {
  margin-top: 1.4em;
  margin-bottom: 1.4em;
}

.star {
  color: #2F5376;
}

.form-color-gray {
  background-color: whitesmoke;
}

html {
  /* position: relative; */
  height: 100%;
}

body {
  height: 100%;
  background-color: whitesmoke;
}

#wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: sticky;
  top: 4rem;
  z-index: 1000;
}

#wrapper #content-wrapper {
  overflow: hidden;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 80px;
}

body.fixed-nav #content-wrapper {
  margin-top: 56px;
  padding-left: 90px;
}

body.fixed-nav.sidebar-toggled #content-wrapper {
  padding-left: 0;
}

@media (min-width: 768px) {
  body.fixed-nav #content-wrapper {
    padding-left: 225px;
  }

  body.fixed-nav.sidebar-toggled #content-wrapper {
    padding-left: 90px;
  }
}

.scroll-to-top {
  position: fixed;
  right: 15px;
  bottom: 15px;
  display: none;
  width: 50px;
  height: 50px;
  text-align: center;
  color: #fff;
  background: rgba(52, 58, 64, 0.5);
  line-height: 46px;
}

.scroll-to-top:focus,
.scroll-to-top:hover {
  color: white;
}

.scroll-to-top:hover {
  background: #343a40;
}

.scroll-to-top i {
  font-weight: 800;
}

.smaller {
  font-size: 0.7rem;
}

.o-hidden {
  overflow: hidden !important;
}

.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

.navbar-nav .form-inline .input-group {
  width: 100%;
}

.navbar-nav {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.navbar-nav .nav-item.active .nav-link {
  color: #fff;
}

.navbar-nav .nav-item.dropdown .dropdown-toggle::after {
  width: 1rem;
  text-align: center;
  float: right;
  vertical-align: 0;
  border: 0;
  font-weight: 900;
  content: '\f105';
  font-family: 'Font Awesome 5 Free';
}

.navbar-nav .nav-item.dropdown.show .dropdown-toggle::after {
  content: '\f107';
}

.navbar-nav .nav-item.dropdown.no-arrow .dropdown-toggle::after {
  display: none;
}

.navbar-nav .nav-item .nav-link:focus {
  outline: none;
}

.navbar-nav .nav-item .nav-link .badge {
  position: absolute;
  margin-left: 0.75rem;
  top: 0.3rem;
  font-weight: 400;
  font-size: 0.5rem;
}

@media (min-width: 768px) {
  .navbar-nav .form-inline .input-group {
    width: auto;
  }
}

.btn-danger {
  background-color: #D75F5A
}

.sidebar {
  width: 90px !important;
  min-height: calc(100vh - 56px);
  transition: all 0.5s;
}

.sidebar .nav-item:last-child {
  margin-bottom: 1rem;
}

.sidebar .nav-item .nav-link {
  text-align: center;
  padding: 0.75rem 1rem;
  width: 90px;
  transition: all 0.5s;
}

.sidebar .nav-item .nav-link span {
  font-size: 0.65rem;
  display: block;
}

.sidebar .nav-item .dropdown-menu {
  position: absolute !important;
  -webkit-transform: none !important;
  transform: none !important;
  left: calc(90px + 0.5rem) !important;
  margin: 0;
}

.sidebar .nav-item .dropdown-menu.dropup {
  bottom: 0;
  top: auto !important;
}

.sidebar .nav-item.dropdown .dropdown-toggle::after {
  display: none;
}

.sidebar .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.sidebar .nav-item .nav-link:active,
.sidebar .nav-item .nav-link:focus,
.sidebar .nav-item .nav-link:hover {
  color: rgba(255, 255, 255, 0.75);
}

.sidebar.toggled {
  width: 0 !important;
  overflow: hidden;
}

@media (min-width: 768px) {
  .sidebar {
    width: 225px !important;
  }

  .sidebar .nav-item .nav-link {
    display: block;
    width: 100%;
    text-align: left;
    padding: 1rem;
    width: 225px;
  }

  .sidebar .nav-item .nav-link span {
    font-size: 1rem;
    display: inline;
  }

  .sidebar .nav-item .dropdown-menu {
    position: static !important;
    margin: 0 1rem;
    top: 0;
  }

  .sidebar .nav-item.dropdown .dropdown-toggle::after {
    display: block;
  }

  .sidebar.toggled {
    overflow: visible;
    width: 90px !important;
    /* transition: all 1.5s; */
  }


  /* @media all and (min-width: 500px) {
    .dropdown-notification {
      min-width: 500px !important; 
    }
  } */

  .sidebar.toggled .nav-item:last-child {
    margin-bottom: 1rem;
  }

  .sidebar.toggled .nav-item .nav-link {
    text-align: center;
    padding: 0.75rem 1rem;
    width: 90px;
  }

  .sidebar.toggled .nav-item .nav-link span {
    font-size: 0.65rem;
    display: block;
  }

  .sidebar.toggled .nav-item .dropdown-menu {
    position: absolute !important;
    -webkit-transform: none !important;
    transform: none !important;
    left: calc(90px + 0.5rem) !important;
    margin: 0;
  }

  .sidebar.toggled .nav-item .dropdown-menu.dropup {
    bottom: 0;
    top: auto !important;
  }

  .sidebar.toggled .nav-item.dropdown .dropdown-toggle::after {
    display: none;
  }
}

.list-group::-webkit-scrollbar {
  width: 3px;
}

.list-group::-webkit-scrollbar-track {
  background: transparent;
}

.list-group::-webkit-scrollbar-thumb {
  background-color: #2F5376;
  border-radius: 20px;
  /* border: 3px solid transparent; */
}

.list-group {
  padding-right: 1rem;
  /* height: 350px !important; */
  border-radius: 0rem !important;
  overflow-x: hidden;
  /* Hide horizontal scrollbar */
  overflow-y: auto;
  /* Add vertical scrollbar */
}

.list-group-item {
  background-color: #E9ECEF;
  border-radius: 1rem !important;
}

@media (min-width: 768px) {
  .dropdown-notification {
    padding: 50% 0% 50% 50% !important;
    margin: 0% !important;
    border-radius: 0rem !important;
    min-width: 350px !important;
    max-width: 350px !important;
    min-height: 100% !important;
    background-color: #F5F5F5;
  }
}

@media (max-width: 768px) {

  .dropdown-notification {
    right: -100px !important;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 50% 0% 50% 50% !important;
    margin: 0% !important;
    border-radius: 0rem !important;
    min-width: 290px !important;
    max-width: 290px !important;
    min-height: 100% !important;
    background-color: #F5F5F5;
  }

}


.sidebar.fixed-top {
  top: 56px;
  height: calc(100vh - 56px);
  overflow-y: auto;
}

.card-body-icon {
  position: absolute;
  z-index: 0;
  top: -1.25rem;
  right: -1rem;
  opacity: 0.4;
  font-size: 5rem;
  -webkit-transform: rotate(15deg);
  transform: rotate(15deg);
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 1;
    column-count: 1;
  }
}

@media (min-width: 768px) {
  .card-columns {
    -webkit-column-count: 2;
    column-count: 2;
  }
}

@media (min-width: 1200px) {
  .card-columns {
    -webkit-column-count: 2;
    column-count: 2;
  }
}

:root {
  --input-padding-x: 0.75rem;
  --input-padding-y: 0.75rem;
}

.card-login {
  max-width: 25rem;
}

.card {
  border: 0px !important;
  border-radius: 0px !important;
}

.form-label-img {
  font-size: 15px;
  font-weight: 700;
  color: #2F5376;
  margin: 0;
  opacity: 1;
  transition: .333s ease top, .333s ease opacity;
}

.card-register {
  max-width: 40rem;
}

.form-label-group {
  position: relative;
}

.form-label-group>input,
.form-label-group>label {
  padding: var(--input-padding-y) var(--input-padding-x);
  height: auto;
}

.form-label-group>label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:placeholder-shown)~label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: #777;
}

footer.sticky-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
  width: calc(100% - 90px);
  height: 80px;
  background-color: #e9ecef;
}

footer.sticky-footer .copyright {
  line-height: 1;
  font-size: 0.8rem;
}

@media (min-width: 768px) {
  footer.sticky-footer {
    width: calc(100% - 225px);
  }
}

@media (max-width: 1600px) {
  html {
    font-size: 90%;
  }
}

@media (max-width: 1450px) {
  html {
    font-size: 80%;
  }
}

@media (max-width: 1200px) {
  html {
    font-size: 75%;
  }
}

@media (max-width: 950px) {
  html {
    font-size: 75%;
  }
}

@media (max-width: 300px) {
  html {
    font-size: 60%;
  }
}

body.sidebar-toggled footer.sticky-footer {
  width: 100%;
}

@media (max-width: 450px) {
  .novo-logo {
     width: 80%;
     margin: 0px;
  }
}

@media (max-width: 350px) {
  .novo-logo {
     width: 75%;
     margin: 0px;
  }
}

@media (min-width: 768px) {
  body.sidebar-toggled footer.sticky-footer {
    width: calc(100% - 90px);
  }
}
@media (min-width: 400) {
  .nav-tabs {
    font-size: 8px;
  }
}

.btn-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.select-radius-right {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

table.table-bordered {
  border-width: 0;
}

table.table-bordered thead {
  border-style: solid;
  border-color: #dee2e6;
  border-width: 1px 0 0 1px;
}

table.table-bordered th {
  border-color: #dee2e6;
  border-width: 0 1px 1px 0;
}

table.table-bordered tbody {
  border-style: solid;
  border-color: #dee2e6;
  border-width: 1px 0 0 1px;
}

table.table-bordered td {
  border-color: #dee2e6;
  border-width: 0 1px 1px 0;
}

.bg-hover-row:hover {
  background-color: #dee2e6;
}

#compras-produtos-psc {
  font-size: 0.90em !important
}

.card-body-form {
  background-color: #e2e7e8;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #2F5376;
  background-color: #2F5376;
}


/* .sidebar-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 48px;
  height: calc(100vh - 48px);
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto;
} */

.nav-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1040;
}

@media (max-width: 767px) {
  .btn-xs-block {
    display: block;
    width: 100%;
  }

  input[type="submit"].btn-xs-block,
  input[type="reset"].btn-xs-block,
  input[type="button"].btn-xs-block {
    width: 100%;
  }

  .btn-block+.btn-xs-block,
  .btn-xs-block+.btn-block,
  .btn-xs-block+.btn-xs-block {
    margin-top: 0.5rem;
  }
}

#root {
  overflow: hidden !important;
}

.custom-control-input:disabled:checked~.custom-control-label::before {
  color: #fff;
  background-color: #545b62 !important;
  border-color: gray;
}

a:hover {
  text-decoration: none !important;
}

.display-5 {
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-6 {
  font-size: 1.3rem;
  font-weight: 300;
  line-height: 1.2;
}

.back-ground-validate {
  background-color: #D2E0E3;
}

.page-link {
  color: #2F5376 !important;
}

.fixed-bottom {
  z-index: 0 !important;
}

.page-item.active .page-link {
  z-index: 0 !important;
  color: #fff;
  background-color: #D2E0E3;
  border-color: #D2E0E3;
}

.page-link:hover {
  cursor: pointer !important;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  background-color: #D2E0E3;
  color: #D2E0E3;
  box-shadow: 0 0 0 0.2rem #D2E0E3;
}

.hover-buttom-pointer {
  cursor: pointer;
}

.linha-vertical {
  border-left: 2px solid;
  box-sizing: border-box;
  color: #fff;
}

.dropdown-item:active {
  background: #D2E0E3;
  color: #000;
}

.dropdown-item:focus {
  background: #D2E0E3;
  color: #000;
}

@-webkit-keyframes shadow-pop-bl {
  0% {
    -webkit-box-shadow: 0 0 #2F5376, 0 0 #2F5376, 0 0 #2F5376, 0 0 #2F5376, 0 0 #2F5376, 0 0 #2F5376, 0 0 #2F5376, 0 0 #2F5376;
    box-shadow: 0 0 #2F5376, 0 0 #2F5376, 0 0 #2F5376, 0 0 #2F5376, 0 0 #2F5376, 0 0 #2F5376, 0 0 #2F5376, 0 0 #2F5376;
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
  }

  100% {
    -webkit-box-shadow: -1px 1px #2F5376, -2px 2px #2F5376, -3px 3px #2F5376, -4px 4px #2F5376, -5px 5px #2F5376, -6px 6px #2F5376, -7px 7px #2F5376, -8px 8px #2F5376;
    box-shadow: -1px 1px #2F5376, -2px 2px #2F5376, -3px 3px #2F5376, -4px 4px #2F5376, -5px 5px #2F5376, -6px 6px #2F5376, -7px 7px #2F5376, -8px 8px #2F5376;
    -webkit-transform: translateX(8px) translateY(-8px);
    transform: translateX(8px) translateY(-8px);
  }
}

@keyframes shadow-pop-bl {
  0% {
    -webkit-box-shadow: 0 0 #2F5376, 0 0 #2F5376, 0 0 #2F5376, 0 0 #2F5376, 0 0 #2F5376, 0 0 #2F5376, 0 0 #2F5376, 0 0 #2F5376;
    box-shadow: 0 0 #2F5376, 0 0 #2F5376, 0 0 #2F5376, 0 0 #2F5376, 0 0 #2F5376, 0 0 #2F5376, 0 0 #2F5376, 0 0 #2F5376;
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
  }

  100% {
    -webkit-box-shadow: -1px 1px #2F5376, -2px 2px #2F5376, -3px 3px #2F5376, -4px 4px #2F5376, -5px 5px #2F5376, -6px 6px #2F5376, -7px 7px #2F5376, -8px 8px #2F5376;
    box-shadow: -1px 1px #2F5376, -2px 2px #2F5376, -3px 3px #2F5376, -4px 4px #2F5376, -5px 5px #2F5376, -6px 6px #2F5376, -7px 7px #2F5376, -8px 8px #2F5376;
    -webkit-transform: translateX(8px) translateY(-8px);
    transform: translateX(8px) translateY(-8px);
  }
}

@keyframes shadow-pop-gr {
  0% {
    -webkit-box-shadow: 0 0 #4B91D6, 0 0 #4B91D6, 0 0 #4B91D6, 0 0 #4B91D6, 0 0 #4B91D6, 0 0 #4B91D6, 0 0 #4B91D6, 0 0 #4B91D6;
    box-shadow: 0 0 #4B91D6, 0 0 #4B91D6, 0 0 #4B91D6, 0 0 #4B91D6, 0 0 #4B91D6, 0 0 #4B91D6, 0 0 #4B91D6, 0 0 #4B91D6;
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
  }

  100% {
    -webkit-box-shadow: -1px 1px #4B91D6, -2px 2px #4B91D6, -3px 3px #4B91D6, -4px 4px #4B91D6, -5px 5px #4B91D6, -6px 6px #4B91D6, -7px 7px #4B91D6, -8px 8px #4B91D6;
    box-shadow: -1px 1px #4B91D6, -2px 2px #4B91D6, -3px 3px #4B91D6, -4px 4px #4B91D6, -5px 5px #4B91D6, -6px 6px #4B91D6, -7px 7px #4B91D6, -8px 8px #4B91D6;
    -webkit-transform: translateX(8px) translateY(-8px);
    transform: translateX(8px) translateY(-8px);
  }
}

.card-effect:hover {
  -webkit-animation: shadow-pop-bl 0.1s ease-in-out both;
  animation: shadow-pop-bl 0.1s ease-in-out both;
}

.card-effect-selected {
  -webkit-animation: shadow-pop-gr 0.1s ease-in-out both;
  animation: shadow-pop-gr 0.1s ease-in-out both;
}

.card-effect-selected:hover {
  -webkit-animation: shadow-pop-gr 0.1s ease-in-out both;
  animation: shadow-pop-gr 0.1s ease-in-out both;
}

.card-effect {
  transition: transform .1s ease;
}

.imagem-produto-venda {
  opacity: 1;
  display: block;
  transition: .5s ease;
  backface-visibility: hidden;
  width: 100px;
  height: 100px;
  background-color: white;
  object-fit: scale-down;
  margin: auto;
}

.imagem-effect {
  opacity: 1;
  display: block;
  transition: .5s ease;
  backface-visibility: hidden;
  width: 250px;
  height: 250px;
  background-color: white;
  object-fit: scale-down;
  margin: auto;
}

.middle {
  transition: .5s ease;
  opacity: 0;
  position: relative;
  transform: translate(0%, -165%);
  -ms-transform: translate(0%, -165%)
}

.avatar:hover .imagem-effect {
  cursor: pointer;
  opacity: 0.3;
}

.logotipo {
  height: 270px;
  width: '90%';
}

.logotipo:hover .imagem-effect {
  cursor: pointer;
  opacity: 0.3;
}

.logotipo:hover .middle {
  cursor: pointer;
  opacity: 1;
}

.avatar:hover .middle {
  cursor: pointer;
  opacity: 1;
}

.avatar {
  height: 100px;
  width: 100px;
}

.imagem {
  object-fit: scale-down;
  height: auto;
  width: auto;
}

.imagem:hover .imagem-effect {
  cursor: pointer;
  opacity: 0.3;
}

.imagem:hover .middle {
  cursor: pointer;
  opacity: 1;
}

.view-form-control {
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1.3rem;
  bottom: 5px;
  margin-top: 5px;
  margin-right: 0px;
  padding-right: 0px;
  margin-left: 0px;
}

.button-facebook {
  background-color: #4267B2;
  display: inline-flex;
  align-items: center;
  color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  padding: 0px;
  border-radius: 2px;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
}

.icon-button-facebook {
  margin-right: 10px;
  background: rgb(255, 255, 255);
  color: #4267B2;
  padding: 10px 15px 10px 15px;
  border-radius: 2px;
}

.label-button-facebook {
  padding: 10px 10px 10px 0px;
  font-weight: 500;
}

.button-apple {
  background-color: #000;
  display: inline-flex !important;
  align-items: center;
  color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px;
  padding: 0px;
  border-radius: 2px;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
}

.icon-button-apple {
  margin-right: 10px;
  background: rgb(255, 255, 255);
  color: #000;
  padding: 10px 13px 10px 15px;
  border-radius: 2px;
}

.label-button-apple {
  padding: 10px 10px 10px 0px;
  font-weight: 500;
}

.input-group>.input-group-prepend {
  margin: auto;
  margin-right: 5px;
}

.input-group .input-group-text {
  width: 100%;
}

.list-produto-fiscal:hover {
  cursor: pointer;
  background-color: #8ab1bb;
}

.list-produto-fiscal-active {
  cursor: pointer;
  background-color: #6ecae0
}

.click-card:hover {
  cursor: pointer;
  transition: all 0.2s;
  border-color: #8ab1bb !important;
  border-bottom: 6px solid;
  border-left: 6px solid;
}

.click-card-active {
  cursor: pointer;
  transition: all 0.2s;
  border-bottom: 6px solid;
  border-left: 6px solid;
}


.center-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.textarea {
  resize: none;
}

.form-label {
  font-size: 15px;
  font-weight: 700;
  color: #2F5376;
  margin: 0;
  display: block;
  opacity: 1;
  -webkit-transition: .333s ease top, .333s ease opacity;
  transition: .333s ease top, .333s ease opacity;
}

.form-control {
  padding: 0.375rem 0.375rem 0.375rem 0.0rem;
  border-radius: 0;
  border-color: #ccc;
  border-width: 0 0 2px 0;
  border-style: none none solid none;
  box-shadow: none;
}

.form-control:focus {
  box-shadow: none;
  border-color: #2F5376;
}

.input-group-text {
  display: contents;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-right: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.js-hide-label {
  opacity: 0;
}

.js-unhighlight-label {
  color: #999
}


.js .inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile+label {
  max-width: 80%;
  font-size: 1.25rem;
  /* 20px */
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  padding: 0.625rem 1.25rem;
  /* 10px 20px */
}

.no-js .inputfile+label {
  display: none;
}

.inputfile:focus+label,
.inputfile.has-focus+label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.inputfile+label svg {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: currentColor;
  margin-top: -0.25em;
  /* 4px */
  margin-right: 0.25em;
  /* 4px */
}

.inputfile-5+label {
  color: #2F5376;
}

.inputfile-5:focus+label,
.inputfile-5.has-focus+label,
.inputfile-5+label:hover {
  color: #2F5376;
}

.inputfile-5+label figure {
  width: 100px;
  height: 135px;
  background-color: #2F5376;
  display: block;
  position: relative;
  padding: 30px;
  margin: 0 auto 10px;
}

.inputfile-5:focus+label figure,
.inputfile-5.has-focus+label figure,
.inputfile-5+label:hover figure {
  background-color: #2F5376;
}

.inputfile-5+label figure::before,
.inputfile-5+label figure::after {
  width: 0;
  height: 0;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
}

.inputfile-5+label figure::before {
  border-top: 20px solid whitesmoke;
  border-left: 20px solid transparent;
}

.inputfile-5+label figure::after {
  border-bottom: 20px solid #2F5376;
  border-right: 20px solid transparent;
}

.inputfile-5:focus+label figure::after,
.inputfile-5.has-focus+label figure::after,
.inputfile-5+label:hover figure::after {
  border-bottom-color: #2F5376;
}

.inputfile-5+label svg {
  width: 100%;
  height: 100%;
  fill: #f1e5e6;
}

.or-seperator {
  margin: 20px 0 10px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  border-top: 1px solid #ccc;
}

.or-seperator i {
  padding: 0 10px;
  background: #FFFFFF;
  position: relative;
  top: -11px;
  z-index: 1;
}

.is-invalid {
  background-image: none !important
}

.text-danger {
  text-transform: none !important
}

.edit-lote-background {
  background-color: #ADB5BD;
}

.shake {
  animation: shake 1.2s;
  animation-iteration-count: initial;
}

.alert-onboarding {
  animation: blink 1.5s infinite;
}

@keyframes blink {
  0% { color: #FFC106; }  /* Vermelho */
  50% { color: transparent; } /* Branco */
  100% { color: #FFC106; } /* Vermelho */
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.modal-header {
  border-bottom: 0px solid !important;
  background-color: whitesmoke !important;
}
.modal-body {
  border-bottom: 0px solid !important;
  background-color: whitesmoke !important;
}
.modal-footer {
  border-top: 0px solid !important;
  border-bottom: 0px solid !important;
  background-color: whitesmoke !important;
}

.pointer {
  cursor: pointer;
}

/* @import url("https://fonts.googleapis.com/css?family=Poppins");

* {
  font-family: "Poppins", sans-serif;
} */

.react-kanban-column {
    margin: 0px !important;
    margin-right: 7px !important;
    background-color: #d6d6d6 !important;
    padding: 0px !important;
}

.react-kanban-board {
  margin: 0px !important;
  padding: 0px !important;
  /* overflow-y: unset !important; */
}

[data-rbd-draggable-id=column-draggable-6] {
    margin: 0px !important;
    padding: 0px !important;
}

[data-rbd-droppable-id="board-droppable"] {
  height: 100% 
}

.ynRLnc {
  display: block !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}

.btn-xm, .btn-group-sm > .btn {
    padding: 0.2rem 0.2rem;
    font-size: 0.575rem;
    line-height: 0.5;
    border-radius: 0.2rem;
}

@media (min-width: 768px) {
  input[type="date"]:not(.has-value).custom-data:before {
    content: attr(placeholder);
    margin-right: 2px !important;
    color: #2F5376 !important;
    font-weight: 700 !important;
  }
}

.margin-button-select {
margin-right: 14rem !important;
}

#appleid-signin {
  margin-top: 0.3rem !important;
  cursor: pointer !important;
}

.react-apple-signin-auth-btn-light {
  width: 101% !important; 
  margin-top: 4px !important;

}

.custom-checkbox-day {
  opacity: 0 !important;
  position: absolute !important;
  left: 11px !important;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.accordion-item:last-of-type {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.accordion-item {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: #e7f1ff;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.teste:hover {
  background-color: yellow;
}

.offcanvas.show {
  transform: none;
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, .2);
  transform: translateX(100%);
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, .2);
  transform: translateY(100%);
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #F5F5F5;
  background-clip: padding-box;
  outline: 0;
  transition: transform .3s ease-in-out;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem;
  overflow-y: auto;
}

.offcanvas-header .btn-close {
  padding: .5rem;
  margin-top: -.5rem;
  margin-right: -.5rem;
  margin-bottom: -.5rem;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: .5;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.fade {
  transition: opacity .15s linear;
}

*,
:after,
:before {
  box-sizing: border-box;
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: .25em;
  color: #000;
  background: transparent url('../img/close.svg') 50%/1em auto no-repeat;
  border: 0;
  border-radius: .25rem;
  opacity: .5;
}

.MuiStepIcon-root.MuiStepIcon-completed {
  color: #2F5376 !important;
}

.MuiStepIcon-root.MuiStepIcon-active {
  color: #2F5376 !important;
}

.custom-center {
  display: block;
  text-align: -webkit-center;
}

.display-none {
  visibility: hidden;
}

.novo-logo {
  margin-bottom: 2px;
  max-width: 245px;
  max-height: 33.5px;
  /* LOGO GORRO */
  /* margin-top: -4px; */
}

.grabbable {
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}

.grabbable:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}

.flex-noshrink {
    flex-shrink: 0;
}

.dashboards-widget {
  display: flex;
  flex-direction: row !important;
  /* text-align: justify !important;
  text-justify: inter-word !important; */
}

.fontSizeM {
  font-size: .750rem;
}

.card-lucro-title {
  padding-right: 2.00rem;
  padding-left: 2.00rem;
}

.my-accordion-header button{
  padding-top: 0.27rem !important;
  padding-bottom: 0.27rem !important;
}

.paddingX-0 > * {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.number-span-paginatio {
  display: inline-block;
  text-align: center;
  width: 20px;
}

.teste-color {
  background-color: #F5F5F5;
}

.custom-no-padding > * {
  padding: 0 !important;
}