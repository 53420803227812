// change the theme
// #2F5376
// 2F5376
$theme-colors: ("primary": #2F5376,
  "secondary": #4B91D6,
  "success": #488B37,
  "danger": #D5615A,
  "warning": #FFC106,
  "custom-blue": #2F5376,
  "custom-gray": #C5C6C3,
  "graysh-blue": #e9ecef);

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@font-face {
  font-family: "Neutra Text";
  src: url("../fonts/NeutraText.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/NeutraText.woff") format("woff"),
    /* Modern Browsers */
    url("../fonts/NeutraText.ttf") format("truetype"),
    /* Safari, Android, iOS */
}

@font-face {
  font-family: "Neutra Text Bold";
  src: url("../fonts/NeutraTextB.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/NeutraTextB.woff") format("woff"),
    /* Modern Browsers */
    url("../fonts/NeutraTextB.ttf") format("truetype"),
    /* Safari, Android, iOS */
}

.logo {
  letter-spacing: 0;
  color: #FFFFFF;
  text-transform: uppercase;
  opacity: 1;
  font-weight: lighter;
  padding-bottom: 8px;
}

.logo-bold {
  font-family: "Neutra Text Bold";
  font-weight: bold;
}

.blue {
  background-color: #2F5376;
}

.center-logo div>.logo {
  margin-left: calc((107vw - 510px)/2);
  margin-right: calc((100vw - 510px)/2);
  max-width: 470px;
  max-height: 73px;
}

.center-logo-active div>.logo {
  margin-bottom: 2px;
  max-width: 245px;
  max-height: 100%;
  transition: 2.0s ease;
}

.hover-line-background {
  background-color: #E9ECEF;
}

.hover-line-background:focus {
  // -webkit-animation: shadow-pop-bl 0.1s ease-in-out both;
  // animation: shadow-pop-bl 0.1s ease-in-out both;
  background-color: #2F5376;
  outline: none;

  & .form-control-plaintext {
    @extend .text-white;
  }

}

.hover-line-background:hover {
  cursor: pointer;
  // -webkit-animation: shadow-pop-bl 0.1s ease-in-out both;
  // animation: shadow-pop-bl 0.1s ease-in-out both;
  background-color: #2F5376;

  & .form-control-plaintext {
    @extend .text-white;
  }

}

.hover-card-background:hover {
  cursor: pointer;
  // -webkit-animation: shadow-pop-bl 0.1s ease-in-out both;
  // animation: shadow-pop-bl 0.1s ease-in-out both;
  background-color: #2F5376;

  & .text-primary {
    @extend .text-white;
  }
}

@media (max-width: 765px) {
  .center-logo div>.logo {
    margin-left: auto;
    margin-right: auto;
    max-width: 300px;
    max-height: 100%;
  }
}

.table-hover tbody tr:hover>td {
  // -webkit-animation: shadow-pop-bl 0.1s ease-in-out both;
  // animation: shadow-pop-bl 0.1s ease-in-out both;
  background-color: #2F5376;
  @extend .text-white;

  & a {
    @extend .text-white;
  }

  & .star {
    @extend .text-white;
  }

  & .btn-primary {
    @extend .btn-secondary;
  }

  & .btn-outline-primary {
    @extend .btn-secondary;
  }

  & .btn-outline-custom-blue {
    @extend .btn-secondary;
  }

  & .text-custom-blue {
    @extend .text-white;
  }

  & .custom-control-input:checked~.custom-control-label::before {
    border-color: #4B91D6;
    background-color: #4B91D6;
  }
}

// input, button, select, textarea, optgroup, option, span, div,table, tr, td {
//     font-family: "Neutra Text";
//       & .font-weight-bold {
//         font-family: "Neutra Text Bold";
//       }
// }


.list-group-item-action:hover {
  // -webkit-animation: shadow-pop-bl 0.1s ease-in-out both;
  // animation: shadow-pop-bl 0.1s ease-in-out both;
  background-color: #2F5376 !important;
  @extend .text-white;

  & h5 {
    @extend .text-white;
  }

  & div {
    @extend .text-white;
  }

  & .btn-primary {
    @extend .btn-secondary;
  }

  & .btn-outline-custom-blue {
    @extend .btn-secondary;
  }

  & .text-custom-blue {
    @extend .text-white;
  }

  & .text-dark {
    @extend .text-white;
  }
}

.timeline>li>.timeline-panel {
  background-color: white;
}

// .table-fix-size .table th, .table td {
//     padding: 0.75rem;
//     vertical-align: top;
//     border-top: 1px solid #dee2e6;
//     max-width: 150px !important;
//     min-width: 150px !important;
// }

.table-fix-size {
  & th {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    max-width: 150px !important;
    min-width: 150px !important;
  }

  & td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    max-width: 150px !important;
    min-width: 150px !important;
  }
}

.display-show:hover {
  cursor: pointer;

  // -webkit-animation: shadow-pop-bl 0.1s ease-in-out both;
  // animation: shadow-pop-bl 0.1s ease-in-out both;
  & .display-none {
    visibility: visible !important;
  }
}

.offcanvas-lg {
  position: fixed;
  bottom: 0;
  // margin-top: 56px;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #F5F5F5;
  background-clip: padding-box;
  outline: 0;
  transition: transform .3s ease-in-out;
}

.offcanvas-lg.show {
  transform: none;
}

.offcanvas-lg-backdrop.show {
  opacity: .5;
}

.offcanvas-lg-backdrop.fade {
  opacity: 0.5;
}

.offcanvas-lg-end {
  top: 0;
  right: 0;
  width: 1500px;
  border-left: 1px solid rgba(0, 0, 0, .2);
  transform: translateX(100%);
}

.offcanvas-lg-start {
  top: 0;
  left: 0;
  width: 1500px;
  border-right: 1px solid rgba(0, 0, 0, .2);
  transform: translateX(100%);
}

.offcanvas-lg-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.offcanvas-lg-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.offcanvas-lg-body {
  flex-grow: 1;
  padding: 1rem;
  overflow-y: auto;
}

.edit-table {
  & td {
    border-top: 0px solid #dee2e6 !important;
    background-color: white !important;
  }

  & th {
    border-top: 0px solid #dee2e6 !important;
    background-color: white !important;
  }

  & tr {
    border-top: 0px solid #dee2e6 !important;
    background-color: white !important;
  }
}

.offcanvas-md {
  position: fixed;
  bottom: 0;
  // margin-top: 56px;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #F5F5F5;
  background-clip: padding-box;
  outline: 0;
  transition: transform .3s ease-in-out;
}

.offcanvas-md.show {
  transform: none;
}

.offcanvas-md-backdrop.show {
  opacity: .5;
}

.offcanvas-md-backdrop.fade {
  opacity: 0.5;
}

.offcanvas-md-end {
  top: 0;
  right: 0;
  width: 750px;
  border-left: 1px solid rgba(0, 0, 0, .2);
  transform: translateX(100%);
}

.offcanvas-md-start {
  top: 0;
  left: 0;
  width: 750px;
  border-right: 1px solid rgba(0, 0, 0, .2);
  transform: translateX(100%);
}

.offcanvas-md-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.offcanvas-md-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.offcanvas-md-body {
  flex-grow: 1;
  padding: 1rem;
  overflow-y: auto;
}

.overflow-height-450 {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  max-height: 450px !important;
  min-height: 450px !important;
}

.overflow-height-450::-webkit-scrollbar {
  width: 4px;
}

.overflow-height-450::-webkit-scrollbar-track {
  background: transparent;
}

.overflow-height-450::-webkit-scrollbar-thumb {
  background-color: #2F5376;
  border-radius: 4px;
  /* border: 3px solid transparent; */
}

.overflow-height-415 {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  max-height: 415px !important;
  min-height: 415px !important;
}

.overflow-height-415::-webkit-scrollbar {
  width: 4px;
}

.overflow-height-415::-webkit-scrollbar-track {
  background: transparent;
}

.overflow-height-415::-webkit-scrollbar-thumb {
  background-color: #2F5376;
  border-radius: 4px;
  /* border: 3px solid transparent; */
}

.overflow-height-260 {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  max-height: 260px !important;
  min-height: 260px !important;
}

.overflow-height-260::-webkit-scrollbar {
  width: 4px;
}

.overflow-height-260::-webkit-scrollbar-track {
  background: transparent;
}

.overflow-height-260::-webkit-scrollbar-thumb {
  background-color: #2F5376;
  border-radius: 4px;
  /* border: 3px solid transparent; */
}

.overflow-height-545 {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  max-height: 545px !important;
  min-height: 545px !important;
}

.overflow-height-545::-webkit-scrollbar {
  width: 4px;
}

.overflow-height-545::-webkit-scrollbar-track {
  background: transparent;
}

.overflow-height-545::-webkit-scrollbar-thumb {
  background-color: #2F5376;
  border-radius: 4px;
  /* border: 3px solid transparent; */
}

.weight-chart {
  top: -0px !important;
  position: relative !important;
}

#calendar {
  width: 100% !important;
  height: 100% !important;
}

.fc-col-header,
.fc-daygrid-body,
.fc-scrollgrid-sync-table,
.fc-timegrid-body,
.fc-timegrid-body table {
  width: 100% !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.chat-messages {
  display: flex !important;
  flex-direction: column !important;
  max-height: 470px !important;
  overflow-y: scroll !important;
}

.chat-message-right {
  flex-direction: row-reverse !important;
  margin-left: auto !important;
}

.chat-message-left {
  margin-right: auto !important;
}

.chat-message-left,
.chat-message-right {
  display: flex !important;
}

.accordion-button {
  color: #2F5376 !important;
}

.custom-premiun-crow {
  position: absolute !important;
  left: 310px !important;
  bottom: 49px !important;
  transform: rotate(50deg) !important;
  font-size: 35px !important;
}

.dropdown-toggle::after {
  display: none !important;
}

.drop-notas {
  display: block !important;
  width: 100% !important;
  padding: 0.25rem 1.5rem !important;
  clear: both !important;
  font-weight: 400 !important;
  color: #212529 !important;
  text-align: inherit !important;
  white-space: nowrap !important;
  background-color: transparent !important;
  border: 0 !important;
}

.custom-dropdown-menu {
  position: relative !important;
  top: 100% !important;
  left: 0 !important;
  z-index: 1050 !important;
  display: block !important;
}

.custom-dropdown .custom-dropdown-menu {
  width: 500px;
  height: 500px;
  overflow-y: auto;
}

.custom-dropdown .dropdown-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-menu-mobile {
  left: -22rem !important;
  right: auto !important;
}

.dropdown-lembrete {
  padding: 25% 25% 25% 35% !important;
  margin: 0% !important;
  border-radius: 0rem !important;
  min-width: 450px !important;
  min-height: 100% !important;
  max-height: 700px !important;
  background-color: #F5F5F5;
}

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
