@charset "UTF-8";

#interactive.viewport {
    position: relative;
}

#interactive.viewport > canvas, #interactive.viewport > video {
    max-width: 100%;
    width: 100%;
}

canvas.drawing, canvas.drawingBuffer {
    position: absolute;
    left: 0;
    top: 0;
}

/* line 16, ../sass/_viewport.scss */
.controls fieldset {
  border: none;
}
/* line 19, ../sass/_viewport.scss */
.controls .input-group {
  float: left;
}
/* line 21, ../sass/_viewport.scss */
.controls .input-group input, .controls .input-group button {
  display: block;
}
/* line 25, ../sass/_viewport.scss */
.controls .reader-config-group {
  float: right;
}
/* line 28, ../sass/_viewport.scss */
.controls .reader-config-group label {
  display: block;
}
/* line 30, ../sass/_viewport.scss */
.controls .reader-config-group label span {
  width: 11rem;
  display: inline-block;
  text-align: right;
}
/* line 37, ../sass/_viewport.scss */
.controls:after {
  content: '';
  display: block;
  clear: both;
}

@media (max-width: 603px) {
  /* line 5, ../sass/phone/_viewport.scss */
  #interactive.viewport {
    width: 100%;
    height: auto;
    overflow: hidden;
  }
}